import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { getHash } from '@/utils/api';

require('dayjs/locale/he');

export default {
  name: 'TemplateFilterItem',
  props: {
    hotel: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    sortedReset: {
      type: Boolean,
      default: true,
    },
    destName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      partnerId: 'GET_PARTNER_ID',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      hotelList: 'GET_DOMESTIC_HOTELS',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
    badgeColor() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.badgeColor;
    },
    badgeText() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.badgeText;
      // return 'Left Text';
    },
    isSalesDeal() {
      return this.hotel.matchingDiscInfo?.matchingDealInfo?.isSalesDeal;
    },
    creditCardRemark() {
      return this.hotel.matchingDiscInfo?.creditCardRemark?.replaceAll('$', '');
    },
    discountedPrice() {
      const { totalAfterDiscounted, total } = this.hotel;
      const { manualDiscount } = this;
      if (this.isOdysseySite) return Math.round(total * (1 - manualDiscount / 100));
      if (!this.isMemberLogin) return totalAfterDiscounted;
      const isAtlantis = this.hotel?.matchingDiscInfo?.atlantisDiscounted || false;
      const isCreditCardRemarkHotel = !!this.hotel?.matchingDiscInfo?.creditCardRemark || false;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscounted : totalAfterDiscounted * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    hotelName() {
      const { hotelList, hotel } = this;
      if (!hotelList.data) return '';
      const matchedHotel = hotelList.data.find((ht) => ht.id === hotel?.hotelInfo?.hotelId);
      return matchedHotel?.hotelName;
    },
    extraCommRemark() {
      const { hotelList } = this;
      if (!hotelList.data) return '';
      return this.hotel?.hotelInfo?.extraCommRemark || '';
    },
    isCardRemark() {
      let stateCardRemark = false;
      if (this.hotel.specialRemarks) {
        this.hotel.specialRemarks.forEach((el) => {
          if (el.remarkCategory === 'cardRemark') stateCardRemark = true;
        });
      }
      return stateCardRemark;
    },
    commission() {
      const { hotelList, isFCLAgent } = this;
      const fcCommPctFromRule = this.hotel.fcCommPctFromRule || 5;
      if (this.hotel.supplierCode === 'ATLANTIS') return 3; // fcCommPctFromRule;
      const hotel = hotelList?.data?.find((ht) => ht.id === this.hotel?.hotelInfo?.hotelId);
      const transientCommission = this.hotel?.hotelInfo?.extraCommission || 0;
      let cms = 0;
      if (isFCLAgent && this.hotel?.matchingDiscInfo?.creditCardRemark) {
        cms = 10;
      } else if (this.hotel?.matchingDiscInfo?.creditCardRemark) {
        // cms = fcCommPctFromRule;
        cms = +hotel.commission - fcCommPctFromRule;
      } else if (isFCLAgent && this.isCardRemark) {
        cms = 10;
      } else if (!isFCLAgent && !this.isCardRemark) {
        cms = fcCommPctFromRule;
      } else if (isFCLAgent) {
        cms = (transientCommission + (hotel?.commission || 0)) || hotel?.commission || 0;
      } else if (this.hotel.agentCommPctFromRule) {
        cms = this.hotel.agentCommPctFromRule;
      } else {
        cms = Number(hotel?.commission + transientCommission) - fcCommPctFromRule;
      }

      return cms;
    },
    groupedPriceListHotels() {
      const { hotelList, hotel } = this;
      const hotels = hotel.moreHotels || [];
      const mHotelInfo = hotelList.data.find((ht) => ht.id === hotel?.hotelInfo?.hotelId);

      if (hotels.length === 0) {
        return [];
      }
      const result = [];
      const gHotels = {};
      hotels.forEach((elm) => {
        const { priceList } = elm;
        elm.mHotelInfo = mHotelInfo;
        if (gHotels[priceList]) {
          gHotels[priceList].push(elm);
        } else {
          gHotels[priceList] = [];
          gHotels[priceList].push(elm);
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const [priceList, pHotels] of Object.entries(gHotels)) {
        const sortedArray = this.sortArray(pHotels, 'totalAfterDiscounted');
        const bestHotel = sortedArray[0];

        if (bestHotel) {
          bestHotel.restHotels = sortedArray;
          result.push({ priceList: priceList === 'null' ? null : priceList, hotel: bestHotel });
        }
      }
      return result;
    },
    remark() {
      return this.hotel.supplierCode !== 'ATLANTIS' ? this.hotel.adminRemark?.trim() : this.hotel.remark?.trim();
    },
    hotelInfoQuery() {
      const { hotel } = this;
      return new URLSearchParams({ city: this.query.city, hotelCode: hotel.exCode }).toString();
    },
    nameHotelPriceFile() {
      return this.hotel?.hotelInfo?.roomNetPricesFileName ?? '';
    },
    hasSpecialRemark() {
      return Boolean(this.groupedPriceListHotels.find((h) => (h.hotel.specialRemarks && h.hotel.specialRemarks.length && h.hotel.specialRemarks[0].remark)));
    },
  },
  data() {
    return {
      mainProps: {
        blankColor: '#bbb',
        class: 'img-fluid rounded',
      },
      query: this.$route.query,
      phone: '',
      email: '',
      nameState: null,
      isDiscounted: false,
      manualDiscount: 0,
    };
  },
  watch: {
    hotel() {
      // this.setHotelValues();
    },
  },
  created() {
    dayjs().locale('he').format();
  },
  mounted() {
    this.isDiscounted = this.hotel.isDealDiscounting || !!this.hotel.isSpecialDiscounting;
  },
  methods: {
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
    getDate(str, formatShowing) {
      const out = formatShowing ?? 'MM/DD';
      return dayjs(str, 'YYYY-MM-DD').format(out);
    },
    getDay(date) {
      return dayjs(date).locale(this.lang).format('dddd');
    },
    orderNow() {
      if (this.isOdysseySite || this.hotel.count === 1 || this.hotel.supplierCode === 'ATLANTIS' || this.hotel.exCode === this.query.exCode || this.isEmalonMode) {
        let body = {
          city: this.query.city,
          hotelCode: this.hotel.exCode,
          checkIn: this.query.checkIn,
          checkOut: this.query.checkOut,
          adult: Number(this.query.adult),
          child: Number(this.query.child),
          infant: Number(this.query.infant),
          lang: this.query.lang,
          includeFlight: this.query.includeFlight,
          suppliers: this.hotel.supplierCode || this.hotel.suppliers,
          returnLog: this.query.returnLog,
          priceList: this.hotel.priceList,
          roomCode: this.hotel.roomCode,
          basisCode: this.hotel.basis,
          manualDiscount: this.manualDiscount,
        };
        if (this.hotel.supplierCode === 'ATLANTIS') body.category = this.hotel.category;
        const { marketerId, subMarketerId, partnerId } = this;
        if (marketerId && marketerId !== '') body = { ...body, marketerId };
        if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };
        if (partnerId) body = { ...body, partnerId };

        if (window.location.host.includes('localhost')) {
          this.$router.push({ path: '/product-page', query: body });
        } else {
          const routeData = this.$router.resolve({ path: '/product-page', query: body });
          window.open(routeData.href, '_blank');
        }
      } else {
        const { exCode } = this.hotel;
        if (exCode === this.query.exCode) return;

        const query = JSON.parse(JSON.stringify(this.query));

        query.exCode = exCode;
        query.hotelCode = exCode;
        query.suppliers = this.hotel.supplierCode || this.hotel.suppliers;
        delete query.sources;

        this.$router.push({ path: '/search-result', query });
      }

      /**
       * When first search result and click 'order' button, send a event to GA
       */

      if (!this.isOdysseySite) {
        const kind = (this.query.exCode) ? 'Second Step' : 'First Step';

        const payload = {
          eventName: 'select_item',
          itemListId: getHash(`${this.destName} ${this.query.checkIn} ${this.query.checkOut} ${kind}`),
          itemListName: `${this.destName} ${this.hotelName} ${this.query.checkIn} ${this.query.checkOut} - ${this.agencyOwnerName}`,
          items: [
            {
              item_id: this.hotel.exCode,
              item_name: `${this.hotelName} - ${kind}`,
              affiliation: this.affiliation,
              item_category: `${this.destName} ${this.hotelName}`,
              item_category2: `${this.query.checkIn} - ${this.query.checkOut}`,
              price: this.hotel.totalAfterDiscounted,
            },
          ],
        };

        if (kind === 'Second Step') {
          payload.currency = 'ILS';
          payload.value = this.hotel.totalAfterDiscounted;
        }

        this.$store.dispatch('SENDING_GA_EVENT', payload);
      }
    },
    goSmartAgency() {
      if (!this.checkFormValidity()) {
        return;
      }
      const body = {
        checkin: this.hotel.checkIn.split('-').reverse().join('/'),
        checkout: this.hotel.checkOut.split('-').reverse().join('/'),
        hotelID4Mail: this.hotel.exCode,
        clientemail: this.email,
        phone: this.phone,
        dest: this.query.city,
        source: 'FLYING',
        price: this.hotel.totalAfterDiscounted,
        adult: this.hotel.adults,
        child: this.hotel.children,
        baby: this.hotel.infant,
      };

      window.location.href = `https://m.flyingcarpet.co.il/deals/api/apiMobile/AddToSmartAgent?${new URLSearchParams(body).toString()}`;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.email = '';
      this.phone = '';
    },
    sortArray(arr, sortItem, order = 1) {
      arr.sort((a, b) => (Number(a[sortItem]) - Number(b[sortItem])) * order);
      return arr;
    },
    gotoHotelsPage(link) {
      const routeData = this.$router.resolve({ path: link });
      window.open(routeData.href, '_blank');
    },
    showHotelPricePDF() {
      return this.hotel?.hotelInfo?.roomNetPricesUrl !== null && this.hotel?.hotelInfo?.roomNetPricesUrl !== '';
    },
    openPricePDF() {
      window.open(this.hotel?.hotelInfo?.roomNetPricesUrl, '_blank');
    },
  },
};
